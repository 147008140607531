import React, { useState, useEffect } from "react";
import AppLayout from "../../../components/layout/AppLayout";
import { Box, Typography, Grid, Card, CardHeader, CardContent, LinearProgress, Stack, Button, IconButton } from "@mui/material";

import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import instance from "../../../auth/utils/useJwt";
import { Link, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import CircularProgressFeedback from "../../../components/General/CircularProgressFeedback";

export default function TrainingModulePages() {

    const [trainings, setTrainings] = useState([])
    const [loading, setLoading] = useState(true)
    const [folderMeta, setFolderMeta] = useState({})
    const [progress, setProgress] = useState({
        completed: 0,
        total: 0
    })
    const { slug } = useParams()

    const fetchTrainings = async () => {
        setLoading(true)
        instance.get(`/training/pages?slug=${slug}`).then(res => {
            setLoading(false)
            setTrainings(res.data?.trainings)
            setFolderMeta(res.data?.folder)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, [])

    const trainingsDone = trainings?.filter(item => item.completed)?.length


    console.log(trainings)
    return (
        <AppLayout title="Training" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Training" }
                ]} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Card>
                    <Box sx={{ borderBottom: "1px solid #ddd", p: 2 }}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Box>
                        <Typography variant="h6">
                            {folderMeta?.name}
                        </Typography>
                        <Typography variant="subtitle2" sx={{
                            color: "#666", fontSize: "0.8rem"
                        }}>{`Total Estimated Time: ${trainings.reduce((acc, t) => acc + t.reading_minutes, 0)
                            } minutes`}
                        </Typography>
                        </Box>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <CircularProgressFeedback
                             variant="determinate"
                            value={
                                (trainingsDone/trainings?.length) * 100
                            } />
                            {
                                `${ (trainingsDone/trainings?.length) * 100}%`
                            }
                        </Stack>
                        </Stack>

                        <Typography  variant="subtitle2" sx={{
                            color: "#444", fontSize: "0.8rem", mt: 2}}>
                                { folderMeta.excerpt}
                            </Typography>
                    </Box>
                </Card>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item md={8}>
                        <Card>
                            <CardContent>
                                {loading && <LinearProgress />}
                                <Box>

                                    {
                                        trainings.map((training, index) => (
                                            <Stack key={`training-${index}`} direction={"row"} justifyContent={"space-between"}>
                                            <Stack key={index} sx={{ mb: 2 }} gap={2} direction={"row"} alignItems={"center"}>
                                                
                                                <Box>
                                                    <Typography variant="h6">
                                                        {training.title}
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{
                                                        color: "#666", fontSize: "0.8rem"
                                                    }}>
                                                        {
                                                            `${training.reading_minutes} minutes`
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {
                                                        training.completed && <CheckCircleOutlineIcon sx={{ color: "green" }} />
                                                    }
                                                </Box>
                                            </Stack>
                                            <Box>
                                                <IconButton component={Link} to={`/training/${folderMeta.slug}/${training.slug}`}>
                                                        <ArrowForwardIcon />
                                                </IconButton>
                                            </Box>
                                            </Stack>
                                        ))
                                    }



                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4}>
                        <Card>
                            <Box sx={{
                                borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
                            }}>Progress</Box>
                            <CardContent>
                                <Typography variant="subtitle2">You have completed {
                                    trainingsDone
                                } out of {
                                        trainings.length
                                    } trainings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </AppLayout>
    )
}